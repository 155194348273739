var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uploading"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isUpload),expression:"!isUpload"}],staticClass:"uploading-box"},[_c('div',{staticClass:"tit"},[_vm._v(" "+_vm._s(_vm.$t('reg_uploading_1'))+" ")]),_c('div',{staticClass:"uploading-cont"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"hide-required-asterisk":true,"size":"small","label-width":"100px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":_vm.$t('reg_uploading_2'),"rules":[
            {
              required: true,
              message: _vm.$t('reg_uploading_3'),
            } ],"prop":"country_name"}},[_c('el-input',{attrs:{"readonly":"","placeholder":_vm.$t('reg_uploading_4')},on:{"focus":function($event){_vm.showPicker = true}},model:{value:(_vm.ruleForm.country_name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "country_name", $$v)},expression:"ruleForm.country_name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('reg_uploading_5'),"rules":[
            {
              required: true,
              message: _vm.$t('reg_uploading_6'),
              trigger: 'blur',
            } ],"prop":"passportcode"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_uploading_7')},model:{value:(_vm.ruleForm.passportcode),callback:function ($$v) {_vm.$set(_vm.ruleForm, "passportcode", $$v)},expression:"ruleForm.passportcode"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('reg_uploading_8'),"rules":[
            {
              required: true,
              message: _vm.$t('reg_uploading_9'),
              trigger: 'change',
            } ],"prop":"sideimage"}},[(!_vm.ruleForm.sideimage)?_c('div',{staticClass:"file_shot"},[_c('i',{staticClass:"el-icon-plus"}),_c('input',{attrs:{"type":"file","capture":"camera","accept":"image/*","id":"upload-file1"},on:{"change":function($event){return _vm.filefileup(1)}}})]):_c('div',{staticClass:"file_shot"},[_c('img',{staticClass:"file-img",attrs:{"src":_vm.ruleForm.sideimage}}),_c('input',{attrs:{"type":"file","capture":"camera","accept":"image/*","id":"upload-file3"},on:{"change":function($event){return _vm.filefileup(3)}}})])]),_c('el-form-item',{attrs:{"label":_vm.$t('reg_uploading_10'),"rules":[
            {
              required: true,
              message: _vm.$t('reg_uploading_11'),
              trigger: 'blur',
            } ],"prop":"positiveimage"}},[(!_vm.ruleForm.positiveimage)?_c('div',{staticClass:"file_shot"},[_c('i',{staticClass:"el-icon-plus"}),_c('input',{attrs:{"type":"file","capture":"camera","accept":"image/*","id":"upload-file2"},on:{"change":function($event){return _vm.filefileup(2)}}})]):_c('div',{staticClass:"file_shot"},[_c('img',{staticClass:"file-img",attrs:{"src":_vm.ruleForm.positiveimage}}),_c('input',{attrs:{"type":"file","capture":"camera","accept":"image/*","id":"upload-file3"},on:{"change":function($event){return _vm.filefileup(3)}}})])]),_c('el-form-item',{attrs:{"label":_vm.$t('reg_uploading_12'),"rules":[
            {
              required: true,
              message: _vm.$t('reg_uploading_13'),
              trigger: 'blur',
            } ],"prop":"passportimage"}},[(!_vm.ruleForm.passportimage)?_c('div',{staticClass:"file_shot"},[_c('i',{staticClass:"el-icon-plus"}),_c('input',{attrs:{"type":"file","capture":"camera","accept":"image/*","id":"upload-file3"},on:{"change":function($event){return _vm.filefileup(3)}}})]):_c('div',{staticClass:"file_shot"},[_c('img',{staticClass:"file-img",attrs:{"src":_vm.ruleForm.passportimage}}),_c('input',{attrs:{"type":"file","capture":"camera","accept":"image/*","id":"upload-file3"},on:{"change":function($event){return _vm.filefileup(3)}}})])]),_c('div',{staticClass:"submit"},[_c('el-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.submitUpload}},[_vm._v(" "+_vm._s(_vm.$t('reg_uploading_14'))+" ")])],1)],1)],1)]),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"title":_vm.$t('reg_uploading_15'),"show-toolbar":"","columns":_vm.countryData,"value-key":"title"},on:{"cancel":function($event){_vm.showPicker = false},"confirm":_vm.onConfirm}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUpload),expression:"isUpload"}],staticClass:"affirm"},[_c('Affirm',{attrs:{"file":_vm.affirmFile,"type":_vm.affirmType},on:{"affirm":_vm.affirmFu}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }