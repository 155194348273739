<template>
  <div class="uploading">
    <div class="uploading-box" v-show="!isUpload">
      <div class="tit">
        {{ $t('reg_uploading_1') }}
      </div>
      <div class="uploading-cont">
        <el-form
          :model="ruleForm"
          :hide-required-asterisk="true"
          size="small"
          ref="ruleForm"
          label-width="100px"
          label-position="left"
          class="demo-ruleForm"
        >
          <el-form-item
            :label="$t('reg_uploading_2')"
            :rules="[
              {
                required: true,
                message: $t('reg_uploading_3'),
              },
            ]"
            prop="country_name"
          >
            <el-input
              v-model="ruleForm.country_name"
              readonly
              @focus="showPicker = true"
              :placeholder="$t('reg_uploading_4')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('reg_uploading_5')"
            :rules="[
              {
                required: true,
                message: $t('reg_uploading_6'),
                trigger: 'blur',
              },
            ]"
            prop="passportcode"
          >
            <el-input
              v-model="ruleForm.passportcode"
              :placeholder="$t('reg_uploading_7')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('reg_uploading_8')"
            :rules="[
              {
                required: true,
                message: $t('reg_uploading_9'),
                trigger: 'change',
              },
            ]"
            prop="sideimage"
          >
            <div class="file_shot" v-if="!ruleForm.sideimage">
              <i class="el-icon-plus"></i>
              <input
                type="file"
                capture="camera"
                accept="image/*"
                id="upload-file1"
                @change="filefileup(1)"
              />
            </div>
            <div class="file_shot" v-else>
              <img class="file-img" :src="ruleForm.sideimage" />
              <input
                type="file"
                capture="camera"
                accept="image/*"
                id="upload-file3"
                @change="filefileup(3)"
              />
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('reg_uploading_10')"
            :rules="[
              {
                required: true,
                message: $t('reg_uploading_11'),
                trigger: 'blur',
              },
            ]"
            prop="positiveimage"
          >
            <div class="file_shot" v-if="!ruleForm.positiveimage">
              <i class="el-icon-plus"></i>
              <input
                type="file"
                capture="camera"
                accept="image/*"
                id="upload-file2"
                @change="filefileup(2)"
              />
            </div>
            <div class="file_shot" v-else>
              <img class="file-img" :src="ruleForm.positiveimage" />
              <input
                type="file"
                capture="camera"
                accept="image/*"
                id="upload-file3"
                @change="filefileup(3)"
              />
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('reg_uploading_12')"
            :rules="[
              {
                required: true,
                message: $t('reg_uploading_13'),
                trigger: 'blur',
              },
            ]"
            prop="passportimage"
          >
            <div class="file_shot" v-if="!ruleForm.passportimage">
              <i class="el-icon-plus"></i>
              <input
                type="file"
                capture="camera"
                accept="image/*"
                id="upload-file3"
                @change="filefileup(3)"
              />
            </div>
            <div class="file_shot" v-else>
              <img class="file-img" :src="ruleForm.passportimage" />
              <input
                type="file"
                capture="camera"
                accept="image/*"
                id="upload-file3"
                @change="filefileup(3)"
              />
            </div>
          </el-form-item>
          <div class="submit">
            <el-button :loading="loading" type="primary" @click="submitUpload">
              {{ $t('reg_uploading_14') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        :title="$t('reg_uploading_15')"
        show-toolbar
        :columns="countryData"
        value-key="title"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <div class="affirm" v-show="isUpload">
      <Affirm :file="affirmFile" :type="affirmType" @affirm="affirmFu"></Affirm>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Picker, Popup } from 'vant'

Vue.use(Picker)
Vue.use(Popup)
import Affirm from './affirm.vue'

export default {
  components: {
    Affirm,
  },
  data() {
    return {
      ruleForm: {
        type: '1',
        step: this.$route.meta.sort,
        url: this.$route.meta.name,
        email: '',
      },
      countryData: [],
      isUpload: false,
      affirmFile: '',
      affirmType: '',
      showPicker: false,
      loading: false,
    }
  },
  created() {
    this.ruleForm.email = this.$route.params.email
    this.initData()
  },
  methods: {
    initData() {
      this.country()
    },
    async country() {
      let res = await this.$axios.post('/api/Common/country')
      if (res.code === 1) {
        this.countryData = res.data
      }
    },
    onConfirm(item) {
      this.ruleForm.country_id = item.id
      this.$set(this.ruleForm, 'country_name', item.title)
      this.showPicker = false
    },
    submitUpload() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          let res = await this.$axios.post('/api/User/register', this.ruleForm)
          this.loading = false
          if (res.code === 1) {
            this.$message({
              message: '请前往pc官网继续注册',
              type: 'success',
            })
          }
        }
      })
    },
    affirmFu(row) {
      if (row.type === 1) {
        this.$set(this.ruleForm, 'sideimage', row.url)
      } else if (row.type === 2) {
        this.$set(this.ruleForm, 'positiveimage', row.url)
      } else if (row.type === 3) {
        this.$set(this.ruleForm, 'passportimage', row.url)
      }
      this.isUpload = false
    },
    // 选择图片
    async filefileup(type) {
      let file = ''
      if (type === 1) {
        file = document.getElementById('upload-file1').files[0]
      } else if (type === 2) {
        file = document.getElementById('upload-file2').files[0]
      } else if (type === 3) {
        file = document.getElementById('upload-file3').files[0]
      }
      console.log(file)
      if (file) {
        if (file.size > 1024 * 1024 * 10) {
          this.$message({
            message: '文件大小不能超过10M',
            type: 'warning',
          })
          return
        }
        this.affirmFile = file
        this.affirmType = type
        this.isUpload = true
      } else {
        console.log('必须有文件')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.uploading {
  padding: 5%;

  .tit {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }
}

.uploading-cont {
  input[type='file'] {
    width: 120px;
    height: 120px;
    opacity: 0;
  }

  .file_shot {
    width: 120px;
    height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    cursor: pointer;

    .el-icon-plus {
      position: absolute;
      top: 40px;
      left: 40px;
      font-size: 40px;
      color: rgba(0, 0, 0, 0.45);
    }

    .file-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 120px;
      // border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
}

.submit {
  .el-button {
    width: 100%;
  }
}
</style>
