<template>
  <div class="affirm">
    <img class="affirm-img" :src="imgUrl" />
    <div class="affirm-tip">
      {{ $t('reg_affirm_1') }}
    </div>
    <div class="affirm-but flex-between">
      <el-button type="primary" plain>
        {{ $t('reg_affirm_2') }}
        <input type="file" capture id="upload-file" @change="filefileup" />
      </el-button>
      <el-button :loading="loading" type="primary" @click="affirmUpload">
        {{ $t('reg_affirm_3') }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['file', 'type'],
  data() {
    return {
      imgUrl: '',
      upFile: '',
      loading: false,
    }
  },
  watch: {
    file(val) {
      if (val) {
        this.fileInit(val)
      }
    },
  },
  methods: {
    fileInit(file) {
      let that = this
      this.upFile = file
      const fr = new window.FileReader()
      fr.onload = async function () {
        that.imgUrl = fr.result
      }
      fr.readAsDataURL(file)
    },
    // 确认 上传图片
    async affirmUpload() {
      let formData = new FormData()
      formData.append('file', this.upFile)
      this.loading = true
      let res = await this.$axios.post('/api/Common/upload', formData)
      this.loading = false
      if (res.code === 1) {
        console.log(res)
        let par = {
          url: res.data.fullurl,
          type: this.type,
        }
        this.$emit('affirm', par)
      }
    },
    // 选择图片
    filefileup() {
      let file = document.getElementById('upload-file').files[0]
      console.log(file)
      if (file.size > 1024 * 1024 * 10) {
        this.$message({
          message: '文件大小不能超过10M',
          type: 'warning',
        })
        return
      }
      if (file) {
        this.fileInit(file)
      } else {
        console.log('必须有文件')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.affirm {
  text-align: center;
}
.affirm-img {
  max-width: 90vw;
  max-height: 60vh;
  margin-top: 20px;
}
.affirm-tip {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}
.affirm-but {
  margin-top: 20px;
  .el-button {
    width: 42%;
    position: relative;
    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      opacity: 0;
    }
  }
}
</style>
